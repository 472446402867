<template>
	<div>
		<div class="query" style="text-align: left;margin-left: 25px;height: 50px;">
			<div>申诉状态：
				<el-select class="select" v-model="state" placeholder="请选择申诉状态" @change="appealList(1)" clearable>
					<el-option v-for="item in stateList" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</div>
		</div>
		<el-table :data="appealData" style="width: 100%" v-loading="loading">
			<el-table-column prop="id" label="申诉ID" align="center" width="100"></el-table-column>
			<el-table-column prop="appealphoto" label="申诉照片" align="center">
				<template slot-scope="scope">
					<el-image style="width: 160px; height: 90px" alt="申诉照片" :src="scope.row.appealphoto"
						:preview-src-list="[scope.row.appealphoto]"></el-image>
				</template>
			</el-table-column>
			<el-table-column prop="remark" label="备注信息" align="center">
				<template slot-scope="scope">
					<span>{{scope.row.remark ? scope.row.remark : '无'}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="authnumber" label="申诉的身份证号" align="center"></el-table-column>
			<el-table-column prop="createtime" label="申诉时间" align="center"></el-table-column>
			<el-table-column prop="statusName" label="申诉状态" align="center"></el-table-column>
			<el-table-column label="审核" align="center" width="100">
				<template slot-scope="scope">
					<el-button type="primary" :disabled="scope.row.statusName != '已申请'" @click="audit(scope.row.id)">审核
					</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="block">
			<el-pagination @current-change="handleCurrentChange" :page-size="pagesize" background
				layout="total, prev, pager, next, jumper" :total="CountNum" style="margin-top: 20px">
			</el-pagination>
		</div>
		<el-dialog title="审核" :visible.sync="dialogFormVisible" width="400px" center>
			<span>备注：</span>
			<el-input v-model="remark" style="width: 300px;"></el-input>
			<template slot="footer">
				<el-button type="success" @click="submit(2)">通过</el-button>
				<el-button type="danger" @click="submit(3)">拒绝</el-button>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		appealList, //申诉列表接口
		appeal //申诉审核接口
	} from '@/api/adminApi.js'
	export default {
		data() {
			return {
				appealData: [], // 申诉列表
				stateList: [{ //申诉状态筛选
					label: '已申请',
					value: 1
				}, {
					label: '已通过',
					value: 2
				}, {
					label: '已拒绝',
					value: 3
				}],
				loading: false, //加载框
				state: null, //筛选条件
				remark: '', //提交审核的备注信息
				statusId: null, //提交审核的认证id
				dialogFormVisible: false, //弹窗
				currpage: 1, // 初始页
				pagesize: 10, // 每页显示条目个数
				CountNum: 0 // 总条数
			}
		},
		mounted() {
			this.appealList(1); // 获取第一页申诉列表
		},
		methods: {
			audit(id) { //审核按钮
				this.statusId = id; //申诉人id
				this.remark = ''; //清空备注
				this.dialogFormVisible = true; //打开弹窗
			},
			async appealList(currpage) { // 申诉列表
				this.loading = true;
				const data = {
					page: currpage, // 当前页数
					limit: this.pagesize, // 每页条数
					status: this.state ? this.state : 100 //筛选申诉状态（1已申请 2已通过 3已拒绝 默认全部）
				};
				let res = await appealList(data); //请求接口
				this.appealData = res.data.List; // 申诉列表
				this.CountNum = parseInt(res.data.CountNum); // 总条数
				this.loading = false;
			},
			async submit(status) { //审核通过拒绝按钮
				const data = {
					id: this.statusId, //申诉id
					status: status, //申诉审核结果( 2通过 3拒绝 )
					remark: this.remark //申诉的备注信息
				};
				let res = await appeal(data); //请求接口
				if (res.status.code == 1) {
					this.$message.success("审核成功");
					this.appealList(1); // 获取第一页申诉列表
				} else {
					this.$message.error(res.status.msg);
				}
				this.dialogFormVisible = false; //关闭弹窗
			},
			handleCurrentChange(val) { // 分页
				this.appealList(val); // 获取申诉列表
				this.currpage = val; //显示页码
			}
		}
	}
</script>
